import React, { lazy, Suspense, useState, useEffect } from "react";
import axios from "axios";
// import Header from "../Header";
import jsonData from "../../component/ai-branding.json"; // Import the JSON file
import "../Desktop/css/TopBanner.css"


const TopBanner = lazy(() => import("./banner/TopBanner"));
const Header = lazy(() => import ("../Header"));
// const Highlights = lazy(() => import("./Highlights"));
const VideoTestimonials = lazy(() => import("../VideoTestimonials"));
const NoOverlays = lazy(() => import("../Nooverlays/Nooverlays"));
const NumberNeverLie = lazy(() => import("../NumbersNeverLie/NumberNeverLie.js"));
const Highlights = lazy(() => import("../Highlights/Highlights"));
const Balancing = lazy(() => import("../Balancing/Balancing"));
const Quote = lazy(() => import("../Quote/Quote"));
const Relieve = lazy(() => import("../NativeVideo/Relieve.js"));
const JoinOurTeam = lazy(() => import("../JoinOurTeam/Joinourteam"));
const Patent = lazy(() => import("../patent/patent"));
const Footer = lazy(() => import("../CommenFooterComponent/index.js"));


export default function DesktopView() {

const [links, setLinks] = useState(null); // Set initial state as null

useEffect(() => {
    const fetchLinks = async () => {
      try {
        const timestamp = Date.now(); // Generate a unique timestamp
        const url = `${process.env.REACT_APP_ANGELAI_JSON}?timestamp=${timestamp}`;
        const response = await axios.get(url);
        setLinks(response.data);
      } catch (error) {
        console.error("Error fetching links:", error);
        setLinks(jsonData); // Use local JSON data if fetching fails
      }
    };

    if (!links) {
      fetchLinks(); // Fetch JSON only if the links data is not available
    }
  }, [links]);

  useEffect(() => {
    if (!links) {
      setLinks(jsonData); // Set local JSON data as fallback initially
    }
  }, []);
    return (
        <div>
          {links ? (
              <>
            <div className="bannerbg">
            
            <Suspense fallback={<div />}>
                <Header />
            </Suspense>
            <Suspense fallback={<div />}>
                <TopBanner />
            </Suspense>
            </div>
            <Suspense fallback={<div />}>
                <Relieve />
            </Suspense>
            <Suspense fallback={<div />}>
                <NoOverlays />
            </Suspense>
           
            <Suspense fallback={<div />}>
                <NumberNeverLie />
            </Suspense>
            <Suspense fallback={<div />}>
                <Highlights links={links}/>
            </Suspense>
            <Suspense fallback={<div />}>
                <Balancing />
            </Suspense>
            <Suspense fallback={<div />}>
                <Quote />
            </Suspense>
            <Suspense fallback={<div />}>
                <JoinOurTeam links={links}/>
            </Suspense>
            <Suspense fallback={<div />}>
                <VideoTestimonials />
            </Suspense>
            <Suspense fallback={<div />}>
                <Patent />
            </Suspense>
            <Suspense fallback={<div />}>
                <Footer links={links}/>
            </Suspense>
            </>
            ) : (
              <div>Loading...</div>
            )}
        </div>
    );
}